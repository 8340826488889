import React, { useEffect, useRef, useState } from 'react'
import { BASE } from '../../basedata/constants'

import './LoadingHandler.sass'
import { fetchWithApiKey } from '../../basedata/ApiCall'

const Counter = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const updateCounter = () => {

      setCount(prevCount => {
        if(prevCount >= 85){
          window.location.reload()
        }
        return Math.min(prevCount + Math.floor(Math.random() * 5) + 1, 97)
      });
    };

    const getRandomInterval = () => Math.floor(Math.random() * (1500 - 500 + 1)) + 2000;

    const intervalId = setInterval(() => {
      updateCounter();
    }, getRandomInterval());

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className='loading-label'>
      {`${count}%`}
    </div>
  );
};

const LoadingHandlerValidator = () => {


  const verifyBiometry = (token: any) => {


    fetchWithApiKey(`${BASE.api.base_url}${BASE.api.document_status}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: BASE.api.token
      },
      body: JSON.stringify({
        "token": token
      })
    })
      .then((resg) => resg.json())
      .then((response) => {
        if (response.message === 'success') {
          if (response.userInformation && response.userInformation?.callbackURL?.length > 0) {
            window.sessionStorage.setItem('callbackurl', response.userInformation.callbackURL)
            // return window.location.href = `${response.userInformation.callbackURL}`
            window.location.reload()

          } else {
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set('doc', 'false');
            window.location.search = searchParams.toString();
            window.location.reload()
          }
        } else if (response.message === 'errorDoc') {
          const searchParams = new URLSearchParams(window.location.search);
          searchParams.set('doc', 'false');
          window.location.search = searchParams.toString();
          window.location.reload()
        }else {
          setTimeout(() => {
            verifyBiometry(token)
          }, 5000);
        }

      })
      .catch((error) => {
        return setTimeout(() => {
          verifyBiometry(token)
        }, 5000);

      })

  }

  const initialized = useRef(false)
  useEffect(() => {
    if (!initialized.current) {
    initialized.current = true
    verifyBiometry(window.sessionStorage.getItem('token'))
    }
  }, [])

  return (
    <>
    <p className='text-center'>
      Validando os teus dados e biometria, podemos levar alguns minutos...
    </p>
    <div className="loading-wrapper">
      <Counter/>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    </>
  )
}

export default LoadingHandlerValidator
