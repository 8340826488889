import React, { Component, useCallback, useEffect, useState } from 'react'
import './UserSignup.sass'
import { BASE } from './../basedata/constants'

import { useForm } from 'react-hook-form'
import LoadingHandler from '../Biometric/StatesComponents/LoadingHandler'
import { fetchWithApiKey } from '../basedata/ApiCall'

interface Props {
  onResponse: (response: any) => void
}

const maskCPF = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

// (00) 00000-0000
const maskPhone = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d{4})(\d)/, '$1-$2')
}

// 00000-000
const maskCEP = (value: any) => {
  return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2')
}

// 00/00/0000
const maskDate = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1')
}

const isValidEmail = (email: any) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )

function UserDocumentCheck({ onResponse }: Props) {
  const [currentStep, setCurrentStep] = useState(1)

  const [registerError, setregisterError] = useState({
    status: false,
    message: ''
  })


  function VerifyUser() {
    const {
      register,
      handleSubmit,
      setError,
      formState: { errors },
      clearErrors
    } = useForm()

    const onSubmit = (data: any) => {
      // @ least one field
      if (!data.document) {
        setError('neitherHasSome', {
          type: 'manual',
          message: 'Tu precisa informar um documento!'
        })
        return
      }

      setCurrentStep(3)

      fetchWithApiKey(`${BASE.api.base_url}${BASE.api.document_check}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': BASE.api.api_key
        },
        body: JSON.stringify({
          document: data.document,
          token: sessionStorage.getItem('token') || 'na'
        })
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.message === 'success') {

            window.sessionStorage.setItem('doccheck', 'valid')

            return window.location.reload()
            // return onResponse({
            //   token: sessionStorage.getItem('token'),
            //   step: 'welcome'
            // })
          }
          return onResponse({
            token: sessionStorage.getItem('token'),
            step: 'documentCheckFail'
          })
        })
        .catch((error) => {
          console.log(error)
        })

    }
    if (currentStep !== 1) {
      return null
    }

    return (
      <>
        <form className="card" onSubmit={handleSubmit(onSubmit)}>
          <h1>
            Validação <span>cadastral</span>
          </h1>
          <p>
          Vamos iniciar com a validação cadastral do teu registro. <br/><br/>
          Por favor informe teu CPF
          </p>
          <div className="form-wrapper">
            <div className="form-floating">
              <input
                placeholder="Documento"
                className="form-control form-control-lg"
                {...register('document')}
              />
              <label className="form-label" htmlFor="document">
                Documento
              </label>
              {errors.neitherHasSome && (
                <span className="label-error">
                  Tu precisa preencher este campo
                </span>
              )}
              {registerError.status && (
                <span className="label-error">{registerError.message}</span>
              )}
            </div>
          </div>
          <div className="form-group form-submit">
            <input
              className="btn btn-primary form-control form-control-lg"
              type="submit"
              value="Avançar"
              onClick={() => clearErrors()}
            />
          </div>
          {/* <a className="help-link" href="#">
          Ajuda
        </a> */}
        </form>
        <ul className="step-counter">
          <li className="active"></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </>
    )
  }

  return (
    <>
      {/* Verify if users exists */}
      <VerifyUser />
      {/* Log user in */}
      {currentStep === 3 && <LoadingHandler />}
    </>
  )
}

export default UserDocumentCheck
